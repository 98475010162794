import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';
import { LOCALES } from '@/core/i18n/locales';
import LanguageSelector from '@/ui/components/button/languageSelector';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import useAppData from '@/utils/hooks/useAppData';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';
import { NavigationItem } from '@/utils/types/strapi-plugin-navigation.d.';

interface FooterMenu {
    title: string,
}

const FooterNavItem: FC<{ item: NavigationItem }> = ({ item }) => {
    const translateRoute = useRouteTranslator();
    if (item.external) {
        return (
            <li>
                <a href={item.path ? item.path : '/'} rel="noreferrer" target="_blank">
                    <Typography
                        as="span"
                        variant="small"
                    >{item.title}
                    </Typography>
                </a>
            </li>
        );
    }

    return (
        <li>
            <Link href={item.related
                ? translateRoute('PAGE', { slug: item.related.slug })
                : translateRoute('PAGE', { slug: item.uiRouterKey })}
            >
                <a>
                    <Typography
                        as="span"
                        variant="small"
                    >{item.title}
                    </Typography>
                </a>
            </Link>
        </li>
    );
};

const FooterNav: FC<{ menu: FooterMenu, navigation: NavigationItem[] }> = ({ menu, navigation }) => (
    <nav>
        <Typography as="p" className="mb-4" variant="h6">{menu.title}</Typography>
        {navigation.length && (
            <ul className="space-y-2">
                {navigation.map((item) => <FooterNavItem key={item.id} item={item} />)}
            </ul>
        )}
    </nav>
);

const FooterLegal: FC<{ navigation: NavigationItem[] }> = ({ navigation }) => (

    navigation.length > 0 ? (
        <>
            {navigation.map((item) => (
                <a
                    key={item.title}
                    href={item.related ? `/${item.related.slug}` : `/${item.uiRouterKey}`}
                >
                    <Typography
                        className="text-dark-grey"
                        variant="tiny"
                    >{item.title}
                    </Typography>
                </a>
            ))}
        </>
    ) : null

);

const Footer: FC = () => {
    const { footer, footerNavigations } = useAppData();
    const routeTranslator = useRouteTranslator();
    const { socialLinks, isLight } = useWebsiteConfig();

    return (
        <footer
            className="pt-10 2xl:pt-[56px] 2xl-down:border-t-grey-stroke 2xl-down:border-t-[1px]"
            style={{ paddingBottom: 'var(--mobile-sticky-panel-height, 0))' }}
        >
            <div className="grid grid-cols-2 2xl:grid-cols-5 gap-x-6 gap-y-8 px-6 2xl:px-10">
                <div className="2xl-down:flex 2xl-down:items-center 2xl-down:col-span-2">
                    <Link href={routeTranslator('INDEX')}>
                        <a>
                            <img
                                alt="Homebox logo"
                                className={classNames(
                                    'h-[53px] w-[53px] 2xl:h-[87px] 2xl:w-[87px] 2xl-down:mr-3',
                                )}
                                height='53'
                                src="/logo.svg"
                                width='53'
                            />
                        </a>
                    </Link>
                    <a href="https://www.grouperousselet.com/" rel="noreferrer" target="_blank">
                        <img
                            alt="Groupe Rousellet logo"
                            className={classNames(
                                'h-[70px] w-[120px] mt-8 2xl:mt-0 2xl:w-[230px] 2xl-down:mr-3',
                            )}
                            src={`/rousselet-logo/${LOCALES.FR}.svg`}
                        />
                    </a>
                </div>
                {footer.menu_1 && <FooterNav menu={footer.menu_1} navigation={footerNavigations.more} />}
                {(footer.menu_2 && !isLight)
                     && <FooterNav menu={footer.menu_2} navigation={footerNavigations.center} />}
                {footer.menu_3 && <FooterNav menu={footer.menu_3} navigation={footerNavigations.services} />}
                {footer.menu_4 && <FooterNav menu={footer.menu_4} navigation={footerNavigations.others} />}
            </div>
            <div
                className={classNames(
                    'px-6 2xl:px-10 2xl:grid 2xl:grid-cols-[1fr_2fr_1fr]',
                    '2xl:border-t-grey-stroke 2xl:border-t-[1px] 2xl:mt-9',
                    '2xl-down:mb-8',
                )}
            >
                <div
                    className="2xl-down:w-1/2  flex items-center 2xl:justify-center gap-x-4 2xl-down:mt-4 2xl-down:mb-9"
                >
                    { socialLinks.enabled && (
                        <>
                            <a href={socialLinks.facebook} rel="noreferrer" target="_blank">
                                <span className="sr-only">Facebook</span>
                                <Icon name="icon-facebook" size={20} />
                            </a>
                            <a href={socialLinks.linkedin} rel="noreferrer" target="_blank">
                                <span className="sr-only">Linkedin</span>
                                <Icon name="icon-linkedin" size={20} />
                            </a>
                            <a href={socialLinks.instagram} rel="noreferrer" target="_blank">
                                <span className="sr-only">Instagram</span>
                                <Icon name="icon-instagram" size={20} />
                            </a>
                        </>
                    )}
                </div>
                <div
                    className={
                        classNames(
                            'flex items-center justify-center gap-x-2',
                            '2xl-down:flex-col',
                            '2xl-down:border-t-grey-stroke 2xl-down:border-t-[1px] 2xl-down:py-6',
                            '2xl:gap-x-10',
                            '2xl:border-x-grey-stroke 2xl:border-x-[1px] 2xl:py-[34px]',
                        )
                    }
                >
                    <Typography
                        as="span"
                        className="text-dark-grey"
                        variant="tiny"
                    >{footer.bloc_2_label_2}
                    </Typography>
                    <FooterLegal navigation={footerNavigations.legal} />
                </div>
                <LanguageSelector className="self-center justify-self-center" />
            </div>
        </footer>
    );
};

export default Footer;
