import { useEffect, useState } from 'react';
import debounce from '@/utils/debounce';

const useSticky = (): boolean => {
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const onScroll = debounce(() => {
            setSticky(window.scrollY > 100);
        }, 150);

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [setSticky]);

    return isSticky;
};

export default useSticky;
