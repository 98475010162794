import classNames from 'classnames';
import { signOut } from 'next-auth/react';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './style.module.css';
import Icon from '@/ui/components/icon';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';

const UserConnectedDropdown = () => {
    const t = useTranslation();
    const translateRoute = useRouteTranslator();
    const [open, setOpen] = useState<boolean>(false);
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (nodeRef.current
            && buttonRef.current
            && !nodeRef.current.contains(event.target as Node)
            && !buttonRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative">
            <button
                ref={buttonRef}
                className={classNames(
                    'text-grey font-bold text-[14px] inline-flex items-center cursor-pointer',
                    'xl:text-black',
                    'xl-down:mb-4',
                )}
                onClick={() => {
                    setOpen(!open);
                }}
                type="button"
            >
                {t('my_account')}
                <Icon
                    className={classNames('xl-down:hidden duration-150', { '-scale-y-100': open })}
                    name="icon-arrow-down"
                />
            </button>
            <CSSTransition
                classNames={{
                    enter: styles.stepAnimationEnter,
                    enterActive: styles.stepAnimationEnterActive,
                    enterDone: styles.stepAnimationEnterDone,
                    exitActive: styles.stepAnimationExitActive,
                    exitDone: styles.stepAnimationExitDone,
                }}
                in={open}
                nodeRef={nodeRef}
                timeout={{ enter: 200, exit: 0 }}
            >
                <nav
                    ref={nodeRef}
                    className={
                        classNames(
                            'xl:p-6 xl:bg-white xl:absolute xl:z-10 xl:top-full xl:right-0 xl:min-w-[240px] xl:shadow-[0_8px_16px_0_rgba(0,0,0,0.08)]',
                            { 'xl:hidden': !open },
                        )
                    }
                >
                    <ul className="flex flex-col gap-4 xl:gap-6">
                        <li>
                            <a
                                className={styles['dropdown-link']}
                                href={translateRoute('SELFSTORAGE_MY_ADS')}
                                onClick={() => setOpen(false)}
                                onKeyDown={(event) => {
                                    if (event.code.toLowerCase() === 'enter') {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {t('my_ads')}
                                <Icon
                                    className="xl:hidden"
                                    name="icon-arrow-right"
                                />
                            </a>
                        </li>
                        <li>
                            <button
                                className={styles['dropdown-link']}
                                onClick={() => {
                                    signOut();
                                    setOpen(false);
                                }}
                                type="button"
                            >
                                {t('disconnect')}
                                <Icon
                                    className="xl:hidden"
                                    name="icon-arrow-right"
                                />
                            </button>
                        </li>
                    </ul>
                </nav>
            </CSSTransition>
        </div>
    );
};

export default UserConnectedDropdown;
