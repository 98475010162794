import classNames from 'classnames';
import { FC, MouseEventHandler } from 'react';
import styles from './header.module.css';

type MenuToggleProps = {
    onClick: MouseEventHandler<HTMLButtonElement>,
    isToggle: Boolean
}

const MenuToggle: FC<MenuToggleProps> = ({ onClick, isToggle }) => (
    <button className={styles['header-toggle']} onClick={onClick} type="button">
        <svg
            className={classNames(
                styles['header-toggle-icon'],
                isToggle ? styles['header-toggle-icon-active'] : '',
            )}
            fill="none"
            height="28"
            viewBox="0 0 21 28"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 10H13.509H19.3923" stroke="#121213" strokeLinecap="round" strokeWidth="2" />
            <path d="M1 18H13.509H19.3923" stroke="#121213" strokeLinecap="round" strokeWidth="2" />
        </svg>
        <span className={`${styles['header-mobile-link']} ${isToggle ? 'sr-only' : ''}`}>Menu</span>
    </button>
);

export default MenuToggle;
