import React, { FC } from 'react';
import styles from '@/ui/components/header/navigation/navigation.module.css';
import CallToAction from '@/ui/components/callToAction';
import useAppData from '@/utils/hooks/useAppData';

const LoginLink: FC = () => {
    const { header: headerData, setOpenedPoppin } = useAppData();

    return (
        <CallToAction
            as="button"
            className={`${styles['navigation-link']} leading-[18px] flex-row-reverse 2xl:flex-row`}
            iconClassName="w-[18px] h-[18px]"
            onClick={() => setOpenedPoppin('auth-form')}
            type="button"
        >
            <span>{headerData.label_connect}</span>
        </CallToAction>
    );
};

export default LoginLink;
