import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';

const Layout: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...props }) => (
    <div className={classNames('pt-[56px] 2xl:pt-[90px] flex flex-col min-h-[100vh]', className)} {...props}>
        {children}
    </div>
);

export default Layout;
