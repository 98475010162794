import { LOCALES } from '@/core/i18n/locales';
import { isoToEmoji } from '@/ui/components/button/languageSelector';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

const useSwissLanguageSwitch = () => {
    const { locale, isEurope } = useWebsiteConfig();
    const translateRoute = useRouteTranslator('en');

    return [
        {
            flag: isoToEmoji('gb'),
            label: 'English',
            isActive: isEurope,
            link: translateRoute('LIST_SELFSTORAGE', { slug: 'self-storage-geneve' }),
            locale: LOCALES.EN,
        },
        {
            flag: isoToEmoji('de'),
            label: 'Deutsch',
            isActive: locale === 'de-CH',
            link: '/',
            locale: LOCALES.DE_CH,
        },
        {
            flag: isoToEmoji('fr'),
            label: 'Français',
            isActive: locale === 'fr-CH',
            link: '/',
            locale: LOCALES.FR_CH,
        },
    ];
};

export default useSwissLanguageSwitch;
