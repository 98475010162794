import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import styles from './menu.module.css';
import stylesNavigation from '@/ui/components/header/navigation/navigation.module.css';
import Icon from '@/ui/components/icon';
import useAppData from '@/utils/hooks/useAppData';
import useMediaQuery from '@/utils/hooks/useMediaQuery';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';
import { NavigationItem, NestedStructure } from '@/utils/types/strapi-plugin-navigation.d.';
import { KnownRoute } from 'routes.config';

type MenuProps = {
    activeMenu: string | null,
    handleOpen: (category: string) => void,
    handleClose: () => void,
}

interface MenuLinkInterface {
    url: string,
    label: string,
}

interface MenuSectionInterface {
    title: string,
}

const FormatDepartments = (regions: { label: string, path: string }[]) => regions.map((department) => ({
    url: department.path,
    label: department.label,
}));

const FormatLinks = (links: NestedStructure<NavigationItem>) => links.items.map((link) => ({
    label: link.title,
    url: link.related ? link.related.slug : link.uiRouterKey,
} as MenuLinkInterface));

const SubMenu: FC<{ type: string, isActive: boolean, handleOpen: Function }> = ({
    type,
    isActive,
    handleOpen,
    children,
}) => (
    <div
        key={type}
        className={classNames(
            styles.menu,
            { [styles['menu-active']]: isActive },
        )}
        onMouseEnter={() => handleOpen(type)}
    >
        {children}
    </div>
);
const MenuSection: FC<{ section: MenuSectionInterface, className?: string }> = ({
    section,
    className,
    children,
}) => (
    <div className={`${styles['menu-section']} ${className}`}>
        <p className={styles['menu-section-title']}>{section.title}</p>
        {children}
    </div>
);

const MenuList: FC<{ links: MenuLinkInterface[] }> = ({ links }) => {
    const translateRoute = useRouteTranslator();

    return (
        <ul className="text-[14px] leading-[28px]">
            {links.map((link: MenuLinkInterface) => (
                <li key={link.label}>
                    <Link href={translateRoute('PAGE', { slug: link.url })}>
                        <a className={styles['menu-link']}>
                            {link.label}
                        </a>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const PlacesList: FC<{ links: MenuLinkInterface[], placeCategory: KnownRoute }> = ({ links, placeCategory }) => {
    const translateRoute = useRouteTranslator();

    return (
        <ul className={`text-[14px] leading-7 ${styles['regions-list']}`}>
            {links.filter(({ url }) => url.length > 0).map((link) => {
                const currentRoute = translateRoute(placeCategory, { slug: link.url });

                return (
                    <li key={link.label}>
                        <Link href={currentRoute}>
                            <a className={styles['menu-link']}>
                                {link.label}
                            </a>
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

const Menu: FC<MenuProps> = ({ activeMenu, handleOpen, handleClose }) => {
    const { header: headerData, headerNavigation, website } = useAppData();
    const { findMyBoxMenuMode } = useWebsiteConfig();
    const translateRoute = useRouteTranslator();
    const t = useTranslation();
    const is2xlViewport = useMediaQuery('(min-width: 1440px)');
    const closeOnMouseLeave = () => {
        if (is2xlViewport) {
            handleClose();
        }
    };

    return (
        <div
            className={classNames(
                styles['menu-container'],
                { [styles['menu-container-open']]: activeMenu !== null },
            )}
            onMouseLeave={closeOnMouseLeave}
        >
            <div className="h-[56px] flex items-center 2xl:hidden">
                <button
                    className={`${stylesNavigation['navigation-link']} w-auto`}
                    onClick={handleClose}
                    type="button"
                >
                    <Icon
                        name="icon-arrow-left"
                        size={18}
                    /> {t('main_menu')}
                </button>
            </div>
            <div className="w-full">

                {headerNavigation.findBox[0] && (
                    <SubMenu
                        handleOpen={handleOpen}
                        isActive={activeMenu === headerNavigation.findBox[0].slug}
                        type={headerNavigation.findBox[0].slug}
                    >
                        <div className="2xl:px-[40px]">
                            <div className="w-full">
                                <div className={`${styles['menu-section']}`}>
                                    <Link href={translateRoute('FIND_MY_BOX')}>
                                        <a className={styles['menu-section-title']}>
                                            {headerData.menu_1[0].title}
                                        </a>
                                    </Link>
                                    <div
                                        className="2xl:grid 2xl:grid-cols-[1fr_max-content]
                                            2xl:grid-gap-10 2xl:items-start pb-4"
                                    >
                                        <PlacesList
                                            links={FormatDepartments(headerData.menu_1[0].menu_details as any as {
                                                label: string,
                                                path: string
                                            }[])}
                                            placeCategory={findMyBoxMenuMode}
                                        />
                                        <span className="2xl-down:hidden mb-[34px]">
                                            <Image
                                                alt=""
                                                height={344}
                                                src="/menu/hb-eu.png?2"
                                                width={233}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SubMenu>
                )}
                {headerNavigation.services[0] && (
                    <SubMenu
                        handleOpen={handleOpen}
                        isActive={activeMenu === headerNavigation.services[0].slug}
                        type={headerNavigation.services[0].slug}
                    >
                        <div className="2xl:px-[40px]">
                            <div
                                className={classNames(
                                    'w-full',
                                    '2xl:grid 2xl:grid-cols-[1fr_max-content] 2xl:gap-10 2xl:items-start 2xl-down:pb-6',
                                )}
                            >
                                {headerNavigation.services[0].items && (
                                    <div className="grid 2xl:grid-cols-3 2xl:gap-6">
                                        <div className={styles['menu-section']}>
                                            {headerNavigation.services[0].items[0] && (
                                                <MenuSection section={headerNavigation.services[0].items[0]}>
                                                    <MenuList
                                                        links={FormatLinks(headerNavigation.services[0].items[0])}
                                                    />
                                                </MenuSection>
                                            )}
                                            {headerNavigation.services[0].items[1] && (
                                                <MenuSection
                                                    className="2xl:mt-[40px]"
                                                    section={headerNavigation.services[0].items[1]}
                                                >
                                                    <MenuList
                                                        links={FormatLinks(headerNavigation.services[0].items[1])}
                                                    />
                                                </MenuSection>
                                            )}
                                        </div>
                                        {headerNavigation.services[0].items[2] && (
                                            <MenuSection section={headerNavigation.services[0].items[2]}>
                                                <MenuList links={FormatLinks(headerNavigation.services[0].items[2])} />
                                            </MenuSection>
                                        )}
                                        {headerNavigation.services[0].items[3] && (
                                            <MenuSection section={headerNavigation.services[0].items[3]}>
                                                <MenuList links={FormatLinks(headerNavigation.services[0].items[3])} />
                                            </MenuSection>
                                        )}
                                        {headerNavigation.services[0].items[4] && (
                                            <MenuSection section={headerNavigation.services[0].items[4]}>
                                                <MenuList links={FormatLinks(headerNavigation.services[0].items[4])} />
                                            </MenuSection>
                                        )}
                                    </div>
                                )}
                                <span className="2xl-down:hidden">
                                    <Image alt="" height={343} src="/menu/storage.png" width={233} />
                                </span>
                            </div>
                        </div>
                        {website && website.name === 'HOMEBOX France' && (
                            <div className="bg-provincial-pink 2xl-down:hidden w-full mt-[34px] pt-[34px] pb-[28px]">
                                <div className="flex items-center justify-center gap-x-6">
                                    <Image alt="" height={29} src="/menu/geometric-box.svg" width={30} />
                                    <div>
                                        <p className="text-[16px] leading-[18px] font-bold mb-[3px]">
                                            {headerData.menu_2_banner_title}
                                        </p>
                                        <p className="text-[14px] leading-[21px] text-dark-grey">
                                            {headerData.menu_2_banner_text}
                                        </p>
                                    </div>
                                    <Link href={translateRoute('SELFSTORAGE_ADD')}>
                                        <a
                                            className="flex items-center
                                                justify-center w-[30px] h-[30px] bg-red rounded-[6px]"
                                        >
                                            <Icon
                                                className="text-white"
                                                name="icon-arrow-right"
                                            />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </SubMenu>
                )}
                {headerNavigation.enterprise[0] && (
                    <SubMenu
                        handleOpen={handleOpen}
                        isActive={activeMenu === headerNavigation.enterprise[0].slug}
                        type={headerNavigation.enterprise[0].slug}
                    >
                        <div className="2xl:px-[40px]">
                            <div
                                className={classNames(
                                    'w-full',
                                    '2xl:grid 2xl:grid-cols-[1fr_max-content] 2xl:gap-10 2xl:items-start',
                                )}
                            >
                                {headerNavigation.enterprise[0].items && (
                                    <div className="2xl:grid 2xl:grid-cols-3 2xl:gap-6">
                                        {headerNavigation.enterprise[0].items.map((menu) => (
                                            <MenuSection
                                                key={menu.title.toString()}
                                                section={menu}
                                            >
                                                <MenuList links={FormatLinks(menu)} />
                                            </MenuSection>
                                        ))}
                                    </div>
                                )}
                                <span className="2xl-down:hidden">
                                    <Image height={167} src="/menu/groupe.png?2" width={235} />
                                </span>
                            </div>
                        </div>
                    </SubMenu>
                )}
            </div>
        </div>
    );
};

export default Menu;
