import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { FC, HTMLAttributes } from 'react';
import { getLanguage, LOCALES } from '@/core/i18n/locales';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import useAppData from '@/utils/hooks/useAppData';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

export function isoToEmoji(code: string): string {
    return (code === LOCALES.CA ? 'ad' : code)
        .split('')
        .map((letter) => (letter.charCodeAt(0) % 32) + 0x1F1E5)
        .map((n) => String.fromCodePoint(n))
        .join('');
}

const LanguageSelector: FC<HTMLAttributes<HTMLButtonElement>> = ({ className = '' }) => {
    const { setOpenedPoppin } = useAppData();
    const { website } = useWebsiteConfig();
    const { locale } = useRouter();

    const localeInLanguage: Record<string, string> = {
        fr: 'Français',
        de: 'Deutsch',
        es: 'Español',
        pt: 'Português',
        ca: 'Català',
        en: 'English',
    };

    return (
        <button
            className={
                classNames(
                    'border-[1px] border-grey-stroke',
                    'py-[10px] px-[14px]',
                    'inline-flex items-center justify-between min-w-[165px]',
                    className,
                )
            }
            onClick={() => setOpenedPoppin('i18n-poppin')}
            type="button"
        >
            <span className="flex items-center">
                <span className="mr-2 rounded-sm">{isoToEmoji(website)}</span>

                <Typography as="span" variant="small">
                    {localeInLanguage[getLanguage(locale)]}
                </Typography>
            </span>
            <Icon name="icon-arrow-up" size={15} />
        </button>
    );
};

export default LanguageSelector;
