import { useRouter } from 'next/router';
import { FC } from 'react';
import styles from './header.module.css';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';

const SimulatorLink: FC<{ className: string }> = ({ className }) => {
    const t = useTranslation();
    const router = useRouter();
    const routeTranslator = useRouteTranslator();
    const simulatorLink = routeTranslator('SIMULATOR');

    const goToSimulator = () => {
        router.push(simulatorLink);
    };

    return (
        <button
            className={`${styles['header-mobile-link']} ${styles['header-simulator']} ${className}`}
            onClick={goToSimulator}
            type="button"
        >{t('simulator')}
            <svg
                fill="none"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4 9.33V5.064a1.067 1.067 0 0 1 1.067-1.067h4.268M19.999 9.33V5.064a1.067 1.067 0 0 0-1.067-1.067h-4.268M19.999 14.664v4.268a1.067 1.067 0 0 1-1.067 1.067h-4.268M11.469 11.465H5.067c-.59 0-1.067.478-1.067 1.067v6.401C4 19.523 4.478 20 5.067 20h6.402c.589 0 1.066-.477 1.066-1.067v-6.401c0-.59-.477-1.067-1.066-1.067Z"
                    stroke="#121213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.6"
                />
            </svg>
        </button>
    );
};

export default SimulatorLink;
