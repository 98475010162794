import classNames from 'classnames';
import Link from 'next/link';
import router from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import useSticky from './hook/useSticky';
import Menu from './menu/menu';
import MenuToggle from './menuToggle';
import Navigation, { NavigationProps } from './navigation/navigation';
import styles from './header.module.css';
import SimulatorLink from '@/ui/components/header/simulatorLink';
import useAppData from '@/utils/hooks/useAppData';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';

const Header: FC = () => {
    const [isOpen, setOpen] = useState(false);
    const [currentMenu, setCurrentMenu] = useState<string | null>(null);
    const applicationData = useAppData();
    const isSticky = useSticky();
    const routeTranslator = useRouteTranslator();
    const homeLink = routeTranslator('INDEX');

    const handleNavigationOpen = (menu: string) => {
        setCurrentMenu(menu);
    };

    const handleClose = () => {
        setCurrentMenu(null);
    };

    useEffect(() => {
        router.events.on('routeChangeComplete', handleClose);

        return () => router.events.off('routeChangeComplete', handleClose);
    }, [router.events]);

    const navigationProps = {
        isSticky,
        isOpen,
        labelCall: applicationData.header.label_call,
        labelConnect: applicationData.header.label_connect,
        labelDisconnect: applicationData.header.label_disconnect,
        labelFree: applicationData.header.label_free,
        handleNavClick: handleNavigationOpen,
        handleNavClose: handleClose,
    } as NavigationProps;

    return (
        <>
            <header
                className={classNames(
                    styles.header,
                    {
                        [styles['header-sticky']]: isSticky,
                        [styles['header-open']]: isOpen,
                    },
                )}
            >
                <div className={classNames(styles['header-inner'], {
                    [styles['header-inner-sticky']]: isSticky,
                    [styles['header-inner-opened']]: isOpen,
                })}
                >
                    <MenuToggle
                        aria-controls="primary-nav secondary-nav ctas-nav"
                        aria-expanded={isOpen}
                        isToggle={isOpen}
                        onClick={() => setOpen(!isOpen)}
                    />
                    {homeLink && (
                        <Link href={homeLink}>
                            <a
                                className={classNames(
                                    styles['header-logo'],
                                    { '2xl-down:!hidden': isOpen },
                                )}
                            >
                                <img
                                    alt="Homebox logo"
                                    className={classNames(
                                        'h-[40px] w-[40px]',
                                        isSticky ? '2xl:h-[48px] 2xl:w-[48px]' : '2xl:h-[64px] 2xl:w-[64px]',
                                    )}
                                    height='64'
                                    src="/logo.svg"
                                    width='64'
                                />
                            </a>
                        </Link>
                    )}
                    <SimulatorLink className={isOpen ? '!hidden' : ''} />
                    <Navigation {...navigationProps} />
                    <Menu activeMenu={currentMenu} handleClose={handleClose} handleOpen={handleNavigationOpen} />
                </div>
            </header>
            <div
                className={classNames(
                    styles['header-backdrop'],
                    { [styles['header-backdrop-active']]: currentMenu !== null },
                )}
                onClick={handleClose}
                role="dialog"
                aria-hidden
            />
        </>
    );
};

export default Header;
