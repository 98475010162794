import { useEffect, useState } from 'react';

const useMediaQuery = (query: string): boolean|undefined => {
    const [match, setMatch] = useState<boolean>();

    useEffect(() => {
        const mediaQuery = window.matchMedia(query);

        setMatch(mediaQuery.matches);

        const listener = (e: MediaQueryListEvent) => setMatch(e.matches);

        mediaQuery.addEventListener('change', listener);

        return () => {
            mediaQuery.removeEventListener('change', listener);
        };
    }, [query]);

    return match;
};

export default useMediaQuery;
