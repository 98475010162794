import classnames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';
import useSwissLanguageSwitch from '@/ui/components/header/navigation/swissLanguageSwitch/useSwissLanguageSwitch';

const MobileLanguageItem: FC<ReturnType<typeof useSwissLanguageSwitch>[number]> = ({
    flag,
    label,
    isActive,
    link,
    locale,
}) => (
    <Link href={link} locale={locale}>
        <a>
            <span className={classnames('border p-2 px-3', { 'bg-red text-white': isActive })}>
                {flag} {label}
            </span>
        </a>
    </Link>
);

const MobileLanguageSwitch = () => {
    const languages = useSwissLanguageSwitch();

    return (
        <div className="flex gap-2 py-8">
            {languages.map((language) => (
                <MobileLanguageItem {...language} />
            ))}
        </div>
    );
};

const DesktopLanguageSwitch = () => {
    const languages = useSwissLanguageSwitch();

    return (
        <div className="flex gap-6">
            {languages
                .filter((language) => !language.isActive)
                .map((language) => (
                    <Link href={language.link} locale={language.locale}>
                        <a className="hover:underline">
                            {language.flag} {language.label}
                        </a>
                    </Link>
                ))}
        </div>
    );
};

interface SwissLanguageSwitchProps {
    isDesktop: boolean;
}

const SwissLanguageSwitch: FC<SwissLanguageSwitchProps> = ({ isDesktop }) => {
    if (isDesktop) {
        return <DesktopLanguageSwitch />;
    }

    return <MobileLanguageSwitch />;
};

export default SwissLanguageSwitch;
