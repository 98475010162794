import classNames from 'classnames';
import { useSession } from 'next-auth/react';
import React, { FC } from 'react';
import styles from '@/ui/components/header/navigation/navigation.module.css';
import LoginLink from '@/ui/components/header/loginLink';
import ConnectedDropdown from '@/ui/components/header/navigation/connectedDropdown/connectedDropdown';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

const NavigationSecondary: FC<{
    isSticky: boolean,
    isOpen: boolean,
    withBottomBorder?: boolean
}> = ({
    isSticky,
    isOpen,
    withBottomBorder = true,
}) => {
    const { loginEnabled } = useWebsiteConfig();
    const t = useTranslation();
    const { data: session } = useSession();
    const isUserConnected = session !== null;

    return (
        <div
            className={classNames(
                'nav-secondary',
                styles.secondary,
                {
                    [styles['secondary-sticky']]: isSticky,
                    [styles['secondary-opened']]: isOpen,
                    [styles['secondary-with-border']]: withBottomBorder,
                },
            )}
            id="secondary-nav"
        >
            <a className="text-[12px] font-bold uppercase hidden 2xl:inline" href="/faq">{t('faq_label')}</a>
            {loginEnabled && (
                <>
                    <hr className={classNames(styles['secondary-separator'])} />
                    {isUserConnected ? (
                        <ConnectedDropdown />
                    ) : (
                        <LoginLink />)}
                </>
            )}
        </div>
    );
};
export default NavigationSecondary;
