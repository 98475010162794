import { Center } from '@homebox/client';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, VFC } from 'react';
import styles from '@/ui/components/header/navigation/navigation.module.css';
import Button, { ButtonColors } from '@/ui/components/button';
import FindBoxButton from '@/ui/components/button/find-a-box';
import PriceButton from '@/ui/components/button/price';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import { getName } from '@/utils/center';
import { GTMRecallButton, GTMTelephoneCenter } from '@/utils/gtm';
import useAppData from '@/utils/hooks/useAppData';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';
import phoneCall from '@/utils/phoneCall';

const CenterName: VFC<{ center: Center }> = ({ center }) => (
    <Typography
        className="text-center"
        variant="h5"
    >{getName(center)}
    </Typography>
);

const MobileStickyPanelCenterBody: VFC = () => {
    const { page, setOpenedPoppin } = useAppData();
    const t = useTranslation();
    const center = page as Center;

    return (
        <div className="flex flex-col md:w-3/4 mx-auto">
            <CenterName center={center} />
            <div className="flex items-center gap-2 mt-3 mb-2">
                <Button
                    asElement="button"
                    className="grow basis-1/2"
                    colorVariant={ButtonColors.GRAY}
                    onClick={() => {
                        phoneCall(center.phone);
                        GTMTelephoneCenter('bandeau_sticky', center);
                    }}
                    type="button"
                >
                    <Icon
                        name="icon-phone"
                        size={18}
                    />
                    {center.phone}
                </Button>
                <PriceButton center={center} className="grow md:basis-1/2" gtmUIPlace="bandeau_sticky" />
            </div>
            <Button
                className={classNames(
                    styles['navigation-call-back'],
                    'grow self-stretch',
                )}
                colorVariant={ButtonColors.GRAY}
                onClick={() => {
                    GTMRecallButton('bandeau_sticky');
                    setOpenedPoppin('recall-form');
                }}
            >
                <Icon name="icon-call" size={18} />
                <span>{t('we_call_you_back')}</span>
            </Button>
        </div>
    );
};

const MobileStickyPanelSimulatorBody: VFC = () => {
    const { setOpenedPoppin } = useAppData();
    const t = useTranslation();
    const { searchEnabled } = useWebsiteConfig();

    return (
        <div className="flex justify-center items-center gap-1 sm:gap-3 md:w-3/4 mx-auto">
            {searchEnabled ? (
                <FindBoxButton
                    asElement="button"
                    className="grow basis-1/2 self-stretch sm-down:!px-2"
                    onClick={() => {
                        setOpenedPoppin('search-box');
                    }}
                />
            ) : null}
            <Button
                className={classNames(
                    styles['navigation-call-back'],
                    'grow self-stretch',
                )}
                colorVariant={ButtonColors.GRAY}
                onClick={() => {
                    GTMRecallButton('bandeau_sticky');
                    setOpenedPoppin('recall-form');
                }}
            >
                <Icon name="icon-call" size={18} />
                <span>{t('we_call_you_back')}</span>
            </Button>
        </div>
    );
};
const MobileStickyPanelDefaultBody: VFC = () => {
    const { header: headerData, setOpenedPoppin } = useAppData();
    const { phoneCallCenter, searchEnabled } = useWebsiteConfig();
    const t = useTranslation();

    return (
        <div className="flex justify-center items-center gap-1 sm:gap-3 md:w-3/4 mx-auto">
            {phoneCallCenter ? (
                <>
                    <a
                        className={classNames(styles['navigation-phone-button'], 'gap-1 grow self-stretch px-1')}
                        href={`tel:${phoneCallCenter}`}
                        onClick={() => GTMTelephoneCenter('bandeau_sticky')}
                    >
                        <span className="flex items-center text-red">
                            <Icon name="icon-phone" size={18} />
                            <span
                                className={classNames(
                                    'font-bold ml-[3px] text-[16px] leading-[17px]',
                                    '2xl:text-[20px] 2xl:leading-[26px]',
                                )}
                            >{phoneCallCenter}
                            </span>
                        </span>
                        <span
                            className="text-[10px] leading-[10px] 2xl:text-[12px] 2xl:leading-[14px] text-right"
                        >{headerData.label_free}
                        </span>
                    </a>
                    <Button
                        className={classNames(
                            styles['navigation-call-back'],
                            'grow self-stretch sm-down:!px-2',
                        )}
                        colorVariant={ButtonColors.GRAY}
                        onClick={() => {
                            GTMRecallButton('bandeau_sticky');
                            setOpenedPoppin('recall-form');
                        }}
                    >
                        <Icon name="icon-call" size={18} />
                        <span>{t('we_call_you_back')}</span>
                    </Button>
                </>
            ) : null}
            {searchEnabled && (
                <Button
                    className={classNames(
                        styles['navigation-search'],
                        'self-stretch sm-down:!px-3',
                    )}
                    onClick={() => setOpenedPoppin('search-box')}
                >
                    <Icon name="icon-search" size={18} />
                    <span
                        className="sm-down:sr-only"
                    >{t('find_storage')}
                    </span>
                </Button>
            )}
        </div>
    );
};

const MobileStickyPanelBody: VFC = () => {
    const { route } = useRouter();
    if (route === '/__route/liste-des-gardes-meubles/[slug]') {
        return <MobileStickyPanelCenterBody />;
    }
    if (route === '/__route/simulateur') {
        return <MobileStickyPanelSimulatorBody />;
    }

    return <MobileStickyPanelDefaultBody />;
};

const MobileStickyPanel: VFC = () => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (window !== undefined && ref.current !== null) {
            const currentHeight = ref.current.offsetHeight;
            document.documentElement.style.setProperty('--mobile-sticky-panel-height', `${currentHeight}px`);
        }
    }, []);

    return (
        <div
            ref={ref}
            className={classNames(
                'z-30 fixed bottom-0 left-0 w-full',
                'border-t border-grey-stroke',
                'bg-white py-3 px-4',
                'sm-down:px-2',
                '2xl:hidden',
            )}
        >
            <MobileStickyPanelBody />
        </div>
    );
};

export default MobileStickyPanel;
