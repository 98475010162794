import classNames from 'classnames';
import React, { ComponentProps, ElementType, HTMLAttributes, ReactNode } from 'react';
import styles from './style.module.css';
import Icon from '@/ui/components/icon';

const CallToActionDefaultType = 'a' as const;

type CallToActionOwnProps<E extends ElementType> = {
    children: ReactNode,
    className?: string,
    iconClassName?: string,
    arrowPlacement?: 'start' | 'end',
    arrowDirection?: 'up' | 'right' | 'down' | 'left',
    as?: E,
} & HTMLAttributes<ElementType>

type CallToActionProps<E extends ElementType> = CallToActionOwnProps<E> &
    Omit<ComponentProps<E>, keyof CallToActionOwnProps<E>>

const CallToAction = React.forwardRef(({
    children,
    className,
    iconClassName,
    arrowPlacement = 'start',
    arrowDirection = 'right',
    as: Tag = CallToActionDefaultType,
    ...props
}: CallToActionProps<ElementType>, ref: React.Ref<ElementType> | null) => (
    <Tag
        ref={ref}
        className={classNames(styles['link-cta'], className)}
        {...props}
    >
        {arrowPlacement === 'start' && <Icon className={iconClassName} name={`icon-arrow-${arrowDirection}`} />}
        {children}
        {arrowPlacement === 'end' && <Icon className={iconClassName} name={`icon-arrow-${arrowDirection}`} />}
    </Tag>
));

export default CallToAction;
