import center from '@homebox/client/js/content-types/center/Center';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import styles from './navigation.module.css';
import Button, { ButtonColors } from '@/ui/components/button';
import NavigationSecondary from '@/ui/components/header/navigation/secondaryNavigation';
import SwissLanguageSwitch from '@/ui/components/header/navigation/swissLanguageSwitch';
import Icon from '@/ui/components/icon';
import { GTMRecallButton, GTMTelephoneCenter } from '@/utils/gtm';
import useAppData from '@/utils/hooks/useAppData';
import useMediaQuery from '@/utils/hooks/useMediaQuery';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

export type NavigationItem = {
    label: string,
    subMenu?: string,
}

export type NavigationProps = {
    isSticky: boolean,
    isOpen: boolean,
    handleNavClick: (category: string) => void,
    handleNavClose: () => void,
}

const NavigationCTAs: FC<{ isSticky: boolean }> = ({ isSticky }) => {
    const { header: headerData, setOpenedPoppin, page } = useAppData();
    const { phoneCallCenter, searchEnabled } = useWebsiteConfig();
    const t = useTranslation();
    const { route } = useRouter();

    return (
        <div className={styles.ctas} id="ctas-nav">
            {phoneCallCenter ? (
                <>
                    <Button
                        className={classNames(
                            styles['navigation-call-back'],
                            { '2xl:w-[40px] 2xl:!px-0': isSticky },
                        )}
                        colorVariant={ButtonColors.GRAY}
                        onClick={() => {
                            GTMRecallButton('header', (route === '/__route/liste-des-gardes-meubles/[slug]'
                    || route === '/__route/_variants/search-or-city/[slug]')
                                ? page as center
                                : null);
                            setOpenedPoppin('recall-form');
                        }}
                    >
                        <Icon name="icon-call" size={18} />
                        <span
                            className={classNames({ '2xl:sr-only': isSticky })}
                        >
                            {headerData.label_call}
                        </span>
                    </Button>
                    <a
                        className={`${styles['navigation-phone-button']}`}
                        href={`tel:${phoneCallCenter}`}
                        onClick={() => GTMTelephoneCenter('header')}
                    >
                        <span className="flex items-center mb-[1px] 2xl:mb-0 text-red">
                            <Icon name="icon-phone" size={18} />
                            <span
                                className={classNames(
                                    'font-bold ml-[3px] text-[16px] leading-[17px]',
                                    '2xl:text-[20px] 2xl:leading-[26px]',
                                )}
                            >{phoneCallCenter}
                            </span>
                        </span>
                        <span
                            className="text-[10px] leading-[10px] 2xl:text-[12px] 2xl:leading-[14px] text-right"
                        >{headerData.label_free}
                        </span>
                    </a>
                </>
            ) : null}
            {searchEnabled && (
                <Button
                    className={classNames(
                        styles['navigation-search'],
                        { '2xl:w-[40px] 2xl:!px-0': !isSticky },
                    )}
                    onClick={() => setOpenedPoppin('search-box')}
                >
                    <Icon name="icon-search" size={18} />
                    <span
                        className={classNames({ '2xl:sr-only': !isSticky })}
                    >{t('find_storage')}
                    </span>
                </Button>
            )}
        </div>
    );
};

const Navigation: FC<NavigationProps> = ({
    isSticky,
    isOpen,
    handleNavClick,
    handleNavClose,
}) => {
    const { headerNavigation } = useAppData();
    const { isSwiss } = useWebsiteConfig();
    const routeTranslator = useRouteTranslator();
    const desktopOnly = useMediaQuery('(min-width: 1440px)');
    const handleDesktopOpenMenu = (type: string) => {
        if (!desktopOnly) {
            return;
        }
        handleNavClick(type);
    };

    const handleDesktopCloseMenu = () => {
        if (!desktopOnly) {
            return;
        }
        handleNavClose();
    };

    return (
        <>
            <div
                className={classNames(
                    styles.primary,
                    {
                        [styles['primary-sticky']]: isSticky,
                        [styles['primary-opened']]: isOpen,
                    },
                )}
                id="primary-nav"
                onMouseLeave={() => handleDesktopCloseMenu()}
            >
                <nav>
                    <ul>{[
                        headerNavigation.findBox,
                        headerNavigation.services,
                        headerNavigation.enterprise,
                        headerNavigation.volume,
                        headerNavigation.magazine,
                    ].map((navigationItems, index) => {
                        if (navigationItems.length === 0) {
                            return null;
                        }
                        const pageRoute = routeTranslator('PAGE', { slug: navigationItems[0].slug });

                        const navigationLink = desktopOnly
                            ? pageRoute
                            : navigationItems[0].slug;

                        return (
                            navigationItems.length > 0 && (
                                <li key={navigationItems[0].id}>
                                    {index < 3 && (
                                        <button
                                            className={styles['navigation-link']}
                                            onClick={() => (navigationItems[0].items
                                                ? handleNavClick(navigationItems[0].slug)
                                                : undefined)}
                                            onMouseEnter={() => (navigationItems[0].items
                                                ? handleDesktopOpenMenu(navigationItems[0].slug)
                                                : handleNavClose())}
                                            type="button"
                                        >{navigationItems[0].title}
                                            <Icon
                                                className={`${styles['navigation-link-icon']} ${styles['navigation-link-icon-mobile']}`}
                                                name="icon-arrow-right"
                                                size={18}
                                            />
                                            {navigationItems[0].items
                                                ? (
                                                    <Icon
                                                        className={`${styles['navigation-link-icon']} ${
                                                            styles['navigation-link-icon-desktop']}`}
                                                        name="icon-arrow-down"
                                                    />
                                                ) : ''}
                                        </button>
                                    )}
                                    {index > 2 && (
                                        <Link
                                            href={navigationLink}
                                            onMouseEnter={() => handleNavClose()}
                                        >
                                            <a className={styles['navigation-link']}>
                                                {navigationItems[0].title}
                                            </a>
                                        </Link>
                                    )}
                                </li>
                            )
                        );
                    })}
                    </ul>
                </nav>
                {isSwiss ? <SwissLanguageSwitch isDesktop={Boolean(desktopOnly)} /> : null}
            </div>
            <NavigationSecondary
                isOpen={isOpen}
                isSticky={isSticky}
            />
            <NavigationCTAs isSticky={isSticky} />
        </>
    );
};

export default Navigation;
