const Debounce = (func: Function, wait: number, immediate: boolean = false, context: any = null) => {
    let result: any;
    let timeout: any = null;

    return (...args: any) => {
        const ctx = context || this;
        const later = () => {
            timeout = null;
            if (!immediate) {
                result = func.apply(ctx, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(ctx, args);
        }

        return result;
    };
};
export default Debounce;
