import { FC } from 'react';
import CoreLayout from '@/ui/components/bodyLayout/coreLayout';
import Footer from '@/ui/components/footer';
import Header from '@/ui/components/header/header';
import MobileStickyPanel from '@/ui/components/mobile-sticky-panel';

const Layout: FC<{
    displayFooter?: boolean,
    className?: string
}> = ({
    children,
    className,
    displayFooter = true,
}) => (
    <CoreLayout className={className} style={{ paddingBottom: 'var(--mobile-sticky-panel-height)' }}>
        <Header />
        <main className="grow flex flex-col">{children}</main>
        {displayFooter && <Footer />}
        <MobileStickyPanel />
    </CoreLayout>
);

export default Layout;
