import { Center } from '@homebox/client';
import { useRouter } from 'next/router';
import React, { ElementType, FC, useState } from 'react';
import Button, { ButtonProps, ButtonSizes } from '@/ui/components/button';
import Icon from '@/ui/components/icon';
import SpinnerLoader from '@/ui/components/loader/spinner';
import { GTMClickPriceButton } from '@/utils/gtm';
import useAppData from '@/utils/hooks/useAppData';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';

type PriceButtonProps = {
        center: Center,
        gtmUIPlace: 'liste' | 'carte' | 'fiche_centre' | 'bandeau_sticky',
        textClassName?: string,
        hideIcon?: boolean,
        label?: string,
} & ButtonProps<ElementType>

const PriceButton: FC<PriceButtonProps> = ({
    center,
    textClassName,
    gtmUIPlace,
    hideIcon = false,
    sizeVariant = ButtonSizes.MEDIUM,
    label,
    ...props
}) => {
    const t = useTranslation();
    const router = useRouter();
    const routeTranslator = useRouteTranslator();
    const { funnelId } = useAppData();
    const funnelPathName = routeTranslator('BOOKING');
    const [displayLoader, setDisplayLoader] = useState(false);

    const buttonLabel = label || t('pricing');

    const ButtonIcon = hideIcon ? null : (
        <Icon
            className="w-[18px] h-[18px]"
            name="icon-search"
            size={24}
        />
    );

    return !funnelId ? null : (
        <Button
            asElement="button"
            type="button"
            {...props}
            onClick={() => {
                setDisplayLoader(true);
                GTMClickPriceButton(center, gtmUIPlace);
                router.push({
                    pathname: funnelPathName,
                    query: { center: center.id, funnel: funnelId },
                });
            }}
            sizeVariant={sizeVariant}
        >
            {displayLoader ? (
                <SpinnerLoader />
            ) : (ButtonIcon)}
            <span className={textClassName}>{buttonLabel}</span>
        </Button>
    );
};

export default PriceButton;
